import http from "../http-common";

class TutorialDataService {
  //getAll(id) {    
  //  return http.post(`/servicelist/${id}`);
  //}

  //get(id) {
  //  return http.get(`/servicelist/${id}`);
  //}

  create(data) {
    return http.post("/addservices", data);
  }

  createextra(data) {
    return http.post("/extracharge", data);
  }

  login(data) {
    return http.post("/login", data);
  }

  profilepic(data) {
    return http.post("/profilepic", data);
  }

  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }

  delete(id) {
    return http.delete(`/tutorials/${id}`);
  }

  deleteAll() {
    return http.delete(`/tutorials`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }
}

export default new TutorialDataService();
