<template>
<div class="wrapper">
<!-- Navbar --> 
<topbar2></topbar2>
    <!-- /.navbar -->
  <!-- Main Sidebar Container -->
  <header2></header2>
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
       <div class="row">    
              <div class="col-md-12">
                 <div class="card-header">
                <h3 class="card-title"> Add Service </h3>
              </div>
              </div>              
            </div>
     
    </div>
    
        <!-- Main content -->
    <section class="content">
      <div class="container-fluid">        
        <!-- Main row -->
        <div class="row">
          <!-- Left col -->
          <div class="col-lg-3">&nbsp;</div>
          <div class="col-lg-6 connectedSortable">
            <!-- Custom tabs (Charts with tabs)-->
            <div class="card">                      
              <div class="card-body">
                <div class="tab-content p-0">
                  <!-- Morris chart - Sales -->
                  <div class="chart tab-pane active">
                       <div v-if="!submitted">
      
      <div class="form-group">
        <label for="description">Service Name</label>
        <input
          class="form-control"
          id="service_name"
          required
          v-model="tutorial.service_name"
          name="service_name"
          placeholder="Service Name"
        />
      </div>

      <div class="form-group">
        <label for="price">Price</label>
        <input
          class="form-control"
          id="price"
          required
          v-model="tutorial.price"
          name="price"
          placeholder="Price"
        />
      </div>

      <button @click="saveTutorial" class="btn btn-success">Submit</button>
    </div>

    <div v-else>
      <h4>You submitted successfully!</h4>
      <button class="btn btn-success" @click="newTutorial">Add More</button>
    </div>
                   </div>                  
                </div>
              </div><!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
          <!-- /.Left col -->
        </div>
        <!-- /.row (main row) -->
      </div><!-- /.container-fluid -->
    </section>


  
  </div>
  <footer2></footer2> 
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import TutorialDataService from "../../services/TutorialDataService";

export default {
  extends:defineComponent,
  components:{
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar    
      },
  name: "add-tutorial",
  data() {
    return {
      tutorial: {
        id: null,
        user_id: "1",
        service_name: "",
        price: "",
        company_id:this.$store.state.auth.user.data[0].company_id,
        published: false
      },
      submitted: false
    };
  },
  methods: {
    showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
     showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
    saveTutorial() {
      var data = {
        user_id: this.tutorial.user_id,
        service_name: this.tutorial.service_name,
        price: this.tutorial.price,
        company_id: this.tutorial.company_id
      };

      TutorialDataService.create(data)
        .then(response => {
          this.showsuccessmsg(response.data.response);                  
          this.tutorial.id = response.data.id;
          console.log(response.data);
          this.submitted = true;
        })
        .catch(e => {
          this.showfailedmsg('Something went wrong');       
          console.log(e);
        });
    },
    
    newTutorial() {
      this.submitted = false;
      this.tutorial = {};
    }
  }
};
</script>

<style> 
  @import '../../assets/dist/css/custom.css'  
</style>